var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

$(document).on('turbolinks:load render_async_load', function() {
  var TheaterButton, init_video;
  init_video = function(element) {
    var time_tracks_path, video, videoId, videojs_default_source;
    if (element.hasClass('play-on-hover') && window.is_mobile) {
      return;
    }
    element.on('contextmenu', function() {
      return false;
    });
    time_tracks_path = element.data('time-tracks-path');
    video = $('<video>', element.data('attributes'));
    if (!element.hasClass('lazy')) {
      $.each(element.data('sources'), function() {
        var source;
        source = $('<source>', this);
        return video.append(source);
      });
    }
    $.each(element.data('subtitles'), function() {
      var track;
      track = $('<track>', this);
      track.attr('kind', 'captions');
      return video.append(track);
    });
    $(element).find('img.fallback').toggleClass('d-none');
    element.append(video);
    element = element.find('.video-js');
    if ((videojs_default_source = window.videojs_default_source)) {
      element.find('source[label=' + videojs_default_source + ']').attr('selected', 'selected');
    } else {
      element.find('source[label=1080p]').attr('selected', 'selected');
    }
    videoId = element.attr('id');
    return videojs(element[0], window.videojs_options).ready(function() {
      var current_time, endcap, last_time, remaining_time, sprite, time_tracker, times;
      video = this;
      video.controlBar.addChild('QualitySelector', {}, 15);
      video.on('qualityRequested', function(event, newSource) {
        return videojs.utils.localStorage.setItem('default_source', newSource['label']);
      });
      video.on('volumechange', function() {
        var volume;
        if (this.muted()) {
          volume = 0;
        } else {
          volume = this.volume();
        }
        return videojs.utils.localStorage.setItem('volume', volume);
      });
      video.on('play', function() {
        var volume;
        if ((volume = videojs.utils.localStorage.getItem('volume'))) {
          this.volume(volume);
        }
        $('.video-js[id!=""][id!="' + videoId + '"]').each(function() {
          return this.player.pause();
        });
        return $('.vjs-endcap').remove();
      });
      video.one('play', function() {
        var label;
        if (typeof gtag !== 'undefined') {
          if ((label = element.data('trackLabel'))) {
            return gtag('event', 'show', {
              event_category: 'Video',
              event_label: label
            });
          }
        }
      });
      if (time_tracks_path) {
        last_time = 0;
        time_tracker = [];
        video.on('timeupdate', function() {
          var current_time;
          current_time = Math.round(video.currentTime());
          if (current_time % 5 === 0 && current_time !== last_time) {
            time_tracker.push(current_time);
            last_time = current_time;
            return $.ajax({
              url: time_tracks_path,
              type: 'PUT',
              data: {
                times: time_tracker
              }
            });
          }
        });
      }
      if ((endcap = element.data('endcap'))) {
        video.on('ended', function() {
          return $('#' + videoId).append($(endcap));
        });
      }
      if ($('.theater-mode-container').length) {
        video.getChild('controlBar').addChild('TheaterButton', {}, 14);
      }
      if (element.parent().hasClass('play-on-hover')) {
        element.closest('.card').on('mouseenter', function() {
          return video.play();
        }).on('mouseleave', function() {
          return video.pause();
        });
      }
      if ($('.track-play').length) {
        video.one('play', function() {
          return $.post($('.track-play').data('track-url'));
        });
      }
      current_time = element.parent().find('.vjs-current-time');
      remaining_time = element.parent().find('.vjs-remaining-time');
      current_time.detach().insertAfter(remaining_time);
      times = current_time.add(remaining_time);
      times.on('click', function() {
        times.toggle();
        if ($(this).hasClass('vjs-remaining-time')) {
          return videojs.utils.localStorage.setItem('show-time', 'current');
        } else {
          return videojs.utils.localStorage.removeItem('show-time');
        }
      });
      if (videojs.utils.localStorage.getItem('show-time') === 'current') {
        remaining_time.click();
      }
      if ((sprite = element.data('sprite'))) {
        return video.spriteThumbnails({
          interval: 10,
          url: sprite,
          width: 120,
          height: 68
        });
      }
    });
  };
  window.lazyFunctions['lazyloadVideojs'] = function(element) {
    var video;
    element = $(element);
    if ((video = element.find('video')[0])) {
      video = videojs(video);
      video.poster(element.data('poster'));
      return video.src(element.data('sources'));
    }
  };
  TheaterButton = (function(superClass) {
    extend(TheaterButton, superClass);

    function TheaterButton(player, options) {
      TheaterButton.__super__.constructor.call(this, player, options);
      this.controlText("Theater mode");
      this.addClass('vjs-theater-mode-control-close');
    }

    TheaterButton.prototype.handleClick = function() {
      $('.theater-mode-container').toggleClass('col-lg-12');
      return this.toggleClass('vjs-theater-mode-control-open');
    };

    return TheaterButton;
  })(videojs.getComponent('Button'));
  videojs.registerComponent('TheaterButton', TheaterButton);
  $(".video-iframe").each(function() {
    return $(this).src = 'about:blank';
  });
  $(".video-stream").click(function() {
    var iframe;
    iframe = $("iframe[name='video-iframe']");
    if ($(this).attr('data-ratio') === '16/9') {
      return iframe.height(iframe.width() / 16 * 9);
    } else {
      return iframe.height(iframe.width() / 4 * 3);
    }
  });
  if ($('.init-video').length) {
    window.videojs_default_source = videojs.utils.localStorage.getItem('default_source');
    window.videojs_options = {
      controlBar: {
        volumePanel: {
          inline: false
        }
      },
      techOrder: ['chromecast', 'html5'],
      plugins: {
        chromecast: {}
      }
    };
    window.is_mobile = /mobi|android/i.test(navigator.userAgent);
  }
  return $('.init-video:not(.initialized)').each(function() {
    $(this).addClass('initialized');
    return init_video($(this));
  });
});

document.addEventListener('turbolinks:before-cache', function() {
  $('img.fallback').removeClass('d-none');
  $('.init-video').removeClass('initialized');
  $('.init-video.lazy').removeClass('entered').removeAttr('data-ll-status');
  return $('.video-js').each(function() {
    return videojs(this).dispose();
  });
});

document.addEventListener('turbolinks:load', function() {
  var el, observer, source, src, video, width;
  if ((el = document.getElementById('main-video'))) {
    video = el.getElementsByTagName('video')[0];
    width = $(window).width();
    source = document.createElement('source');
    source.type = 'video/mp4';
    if (width <= 480 && (src = video.dataset.w480)) {
      source.src = src;
    } else if (width <= 720 && (src = video.dataset.w720)) {
      source.src = src;
    } else if (width <= 1080 && (src = video.dataset.w1080)) {
      source.src = src;
    } else {
      source.src = video.dataset.web;
    }
    video.append(source);
    observer = new IntersectionObserver((function(changes, observer) {
      if (changes[0].isIntersecting) {
        if (video.paused) {
          return video.play();
        }
      } else if (!video.paused) {
        return video.pause();
      }
    }), {
      root: null,
      rootMargin: '100px',
      threshold: 1
    });
    observer.observe(el);
    document.addEventListener('visibilitychange', function() {
      if (document.visibilityState === 'hidden') {
        return video.pause();
      }
    });
    return document.addEventListener('turbolinks:before-cache', function() {
      video.pause();
      return video.remove();
    });
  }
});
